import { Storage } from "@bumi/web/storage"
import { ParentProps, createContext, useContext } from "solid-js"

const AuthContext = createContext<{
  userID: string
  workspaceID: string
  login: string
  token: string
}>()

export function AuthProvider(props: ParentProps) {
  const fragment = new URLSearchParams(location.hash.substring(1))

  const access_token = fragment.get("access_token")

  if (access_token) {
    Storage.set("session", access_token)
  }
  const url =
    import.meta.env.VITE_AUTH2_URL +
    "/authorize?" +
    new URLSearchParams({
      client_id: "bumi",
      redirect_uri: location.origin,
      response_type: "token",
      provider: "workspace",
      workspace: location.host.split(".")[0],
    })
  const session = Storage.get("session")
  if (!session) {
    location.href = url
    return
  }
  const [_headerEncoded, payloadEncoded] = session.split(".")
  const payload = JSON.parse(
    atob(payloadEncoded.replace(/-/g, "+").replace(/_/g, "/"))
  )
  if (payload.exp - Date.now() / 1000 <= 0) {
    console.log("token expired")
    location.href = url
    return null
  }
  console.log(payload)

  return (
    <AuthContext.Provider
      value={{
        ...payload.properties,
        login: url,
        token: session,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const auth = useContext(AuthContext)
  if (!auth) throw new Error("No auth context")
  return auth
}
