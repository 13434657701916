export * as RecordStore from "./record"

import { ReadTransaction, WriteTransaction } from "replicache"
import { Record } from "@bumi/core/record"

export function key(schemaID: string, recordID?: string) {
  return `/record/${schemaID}/${recordID || ""}`
}

export function list(schemaID: string) {
  return async (tx: ReadTransaction) => {
    const result =
      (await tx.scan({ prefix: `/record/${schemaID}/` }).toArray()) || []
    return result as Record.Info[]
  }
}

export function fromID(schemaID: string, recordID: string) {
  return async (tx: ReadTransaction) => {
    const result = await tx.get(`/record/${schemaID}/${recordID}`)
    return result as Record.Info | undefined
  }
}

export async function put(tx: WriteTransaction, record: Partial<Record.Info>) {
  await tx.put(`/record/${record.schemaID}/${record.recordID}`, record)
}

export async function remove(tx: WriteTransaction, record: Record.Info) {
  await tx.del(`/record/${record.schemaID}/${record.recordID}`)
}
