import { Dialog, DialogControl } from "@bumi/ui/v3"
import { Record } from "@bumi/core/record"
import { SessionStore } from "@bumi/workspace/data/next/session"
import { RecordStore } from "@bumi/workspace/data/record"
import { parseTimestamp } from "@bumi/workspace/data/schema"
import { createGet, useReplicache } from "@bumi/workspace/replicache"
import { DateTime } from "luxon"
import { Show, createSignal } from "solid-js"
import { Session } from "@bumi/core/session"

export interface Props {
  onControl: (control: ReturnType<typeof createControl>["external"]) => void
}

export type SessionUnlockDialogControl = ReturnType<
  typeof createControl
>["external"]

function createControl(control: () => DialogControl) {
  let session: Session.Info
  let record: Record.Info

  return {
    get session() {
      return session
    },
    get record() {
      return record
    },
    external: {
      show(s: Session.Info, r: Record.Info) {
        session = s
        record = r
        control().show()
      },
    },
  }
}

export function SessionUnlockDialog(props: Props) {
  let dialogControl: DialogControl
  const control = createControl(() => dialogControl)
  props.onControl(control.external)
  const rep = useReplicache()

  return (
    <Dialog
      onControl={(c) => (dialogControl = c)}
      onConfirm={() => {
        rep.mutate.session_open(control.session!.sessionID)
        dialogControl.hide()
      }}
      title="Are you sure you want to unlock?"
    >
      <Show when={control.session && control.record}>
        {control.session?.name} with {control.record!.name} on{" "}
        {parseTimestamp(
          control.session!.timeAppointment || control.session!.timeCreated
        )!.toLocaleString(DateTime.DATETIME_MED)}
      </Show>
    </Dialog>
  )
}
