import { DateTime } from "luxon"

export function parseTimestamp(input: string) {
  return DateTime.fromSQL(input, { zone: "utc" }).toLocal()
}

export function parseLooseDate(input: string) {
  const splits = input.split("/")
  const month = parseInt(splits[0])
  if (!month || month > 12) return

  const day = parseInt(splits[1])
  if (!day || day > 31) return

  let year = parseInt(splits[2])
  if (!year) year = new Date().getFullYear()

  // Handle 2 digit years
  while (true) {
    const next = year + 1000
    if (next > new Date().getFullYear()) break
    year = next
  }

  return DateTime.fromObject({ year, month, day }).toSQLDate()
}
