export interface Data {}

export function set<K extends keyof Data>(key: K, data: Data[K]) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function get<K extends keyof Data>(key: K): Data[K] | undefined {
  const raw = localStorage.getItem(key)
  if (!raw) return
  try {
    return JSON.parse(raw) as Data[K]
  } catch {
    return
  }
}

export function clear() {
  localStorage.clear()
}

export * as Storage from "./storage"
