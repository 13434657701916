import { IconDotsVertical, IconUnlock } from "@bumi/ui/icons/custom"
import { Menu } from "@bumi/ui/v3/menu"
import {
  SessionUnlockDialog,
  SessionUnlockDialogControl,
} from "./session-unlock-dialog"
import type { Session } from "@bumi/core/session"
import type { Record } from "@bumi/core/record"

export function SessionMenu(props: {
  session: Session.Info
  record: Record.Info
}) {
  let unlockSession: SessionUnlockDialogControl
  return (
    <>
      <SessionUnlockDialog onControl={(control) => (unlockSession = control)} />
      <Menu trigger={<IconDotsVertical />}>
        <Menu.Item
          onSelect={() => {
            if (!props.session.timeClosed) return
            unlockSession.show(props.session, props.record)
          }}
        >
          <Menu.ItemIcon>
            <IconUnlock />
          </Menu.ItemIcon>
          <span>Unlock</span>
        </Menu.Item>
      </Menu>
    </>
  )
}
