import { JSX } from "solid-js/jsx-runtime"

export const Logo = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => (
  <svg
    viewBox="0 0 100 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.5783 14.6971C42.5783 19.5788 39.0995 22.9584 34.3322 22.9584C32.0989 22.9584 30.1662 22.2073 28.7489 20.9556L28.0188 22.4994H24.6259V0.010498H29.565V7.72932C30.8964 6.85313 32.5284 6.39418 34.3752 6.39418C39.0995 6.39418 42.5783 9.77377 42.5783 14.6971ZM37.6392 14.6971C37.6392 12.4023 35.8783 10.6917 33.4303 10.6917C30.9822 10.6917 29.2214 12.4023 29.2214 14.6971C29.2214 16.9919 30.9822 18.7026 33.4303 18.7026C35.8783 18.6608 37.6392 16.9502 37.6392 14.6971Z"
      fill="#0D0D55"
    />
    <path
      d="M61.7332 6.89478V22.4993H58.3403L57.4813 20.7052C56.2358 22.1655 54.3031 23 52.1128 23C48.2904 23 45.9282 20.7052 45.9282 16.1991V6.89478H50.8243V15.3229C50.8243 17.6177 51.941 18.7025 53.573 18.7025C55.3768 18.7025 56.7941 17.3256 56.7941 14.4884V6.89478H61.7332Z"
      fill="#0D0D55"
    />
    <path
      d="M90.9809 12.8195V22.4576H86.0848V13.654C86.0848 11.5261 85.1829 10.6499 83.7656 10.6499C82.1765 10.6499 80.931 11.8599 80.931 14.2381V22.4159H76.0349V13.654C76.0349 11.5261 75.133 10.6499 73.7157 10.6499C72.1266 10.6499 70.8811 11.8599 70.8811 14.2381V22.4159H65.985V6.89483H69.378L70.151 8.56376C71.3536 7.22862 73.1574 6.43587 75.0901 6.43587C77.2804 6.43587 78.9554 7.31206 79.9862 8.89755C81.1458 7.35379 83.0784 6.43587 85.1829 6.43587C88.6617 6.39415 90.9809 8.64721 90.9809 12.8195Z"
      fill="#0D0D55"
    />
    <path
      d="M95.0181 6.89482H99.9142V22.4994H95.0181V6.89482ZM100 2.38869C100 3.68212 98.9264 4.7252 97.595 4.7252C96.2636 4.7252 95.1899 3.68212 95.1899 2.38869C95.1899 1.09527 96.2636 0.0521851 97.595 0.0521851C98.9264 0.0521851 100 1.13699 100 2.38869Z"
      fill="#0D0D55"
    />
    <path
      d="M14.7907 8.93945C11.5267 5.76847 6.24406 5.76847 2.97999 8.93945C-2.30264 14.0714 1.43386 22.7916 8.8639 22.7916C16.3369 22.7916 20.0734 14.0714 14.7907 8.93945Z"
      fill="#FC7D7E"
    />
    <path
      d="M4.31123 0.719728C3.32342 -0.239909 1.73434 -0.239909 0.746527 0.719728C-0.842556 2.26349 0.274097 4.89207 2.5074 4.89207C4.78366 4.89207 5.90031 2.26349 4.31123 0.719728Z"
      fill="#FC7D7E"
    />
  </svg>
)

export const CircleLogo = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="15.3333"
      fill="#0D0D55"
      stroke="#F6F6F7"
      stroke-width="1.33333"
    />
    <path
      d="M20.0921 14.8963C18.0147 12.8123 14.6527 12.8123 12.5754 14.8963C9.21337 18.2691 11.5914 24 16.32 24C21.0761 24 23.4541 18.2691 20.0921 14.8963Z"
      fill="#FC7D7E"
    />
    <path
      d="M13.1753 8.49041C12.5241 7.83653 11.4766 7.83653 10.8254 8.49041C9.77782 9.54229 10.5139 11.3333 11.9862 11.3333C13.4868 11.3333 14.2229 9.54229 13.1753 8.49041Z"
      fill="#FC7D7E"
    />
  </svg>
)
