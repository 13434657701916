import { Record } from "@bumi/core/record"
import type { Session } from "@bumi/core/session"
import {
  NextSessionStore,
  SessionDetailStore,
  SessionStore,
} from "@bumi/workspace/data/next/session"
import { RecordStore } from "@bumi/workspace/data/record"
import { useParams } from "@solidjs/router"
import { createGet, useReplicache } from "../../../replicache"
import {
  Accessor,
  ParentProps,
  Show,
  createContext,
  createEffect,
  createSignal,
  useContext,
} from "solid-js"
import { WindowStore } from "@bumi/workspace/data/window"

interface Context {
  session: Accessor<Session.Info>
  record: Accessor<Record.Info>
  detail: Accessor<Session.Detail>
  smart: {
    field?: string
    setField: (field: string) => Promise<void>
    unsetField: () => void
  }
}

const context = createContext<Context>()

export function SessionContext(props: ParentProps) {
  const params = useParams()
  const session = SessionStore.watch.get(() => params.sessionID)
  const detail = SessionDetailStore.watch.get(() => params.sessionID)
  const rep = useReplicache()
  const record = createGet<Record.Info>(() =>
    RecordStore.key("bumi.customers", session()?.recordID)
  )

  const [focusedField, setField] = createSignal<string>()

  createEffect(() => {
    WindowStore.create(rep, "session", params.sessionID)
  })

  createEffect(() => {
    const recordID = record()?.recordID
    if (!recordID) return
    WindowStore.create(rep, "record", recordID)
  })

  return (
    <Show when={record() && session()}>
      <context.Provider
        value={{
          session: session as Context["session"],
          detail: detail as Context["detail"],
          record: record as Context["record"],
          smart: {
            get field() {
              return focusedField()
            },
            setField: async (fieldID: string) => {
              setField(fieldID)
            },
            unsetField: () => setField(undefined),
          },
        }}
      >
        {props.children}
      </context.Provider>
    </Show>
  )
}

export function useSession() {
  const ctx = useContext(context)
  if (!ctx) throw new Error("SessionContext not found")
  return ctx.session
}

export function useDetail() {
  const ctx = useContext(context)
  if (!ctx) throw new Error("SessionContext not found")
  return ctx.detail
}

export function useRecord() {
  const ctx = useContext(context)
  if (!ctx) throw new Error("SessionContext not found")
  return ctx.record
}

export function useSmart() {
  const ctx = useContext(context)
  if (!ctx) throw new Error("SessionContext not found")
  return ctx.smart
}
