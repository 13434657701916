import { Route, Routes, useParams } from "@solidjs/router"
import { createMemo, Show } from "solid-js"
import { createSubscription } from "@bumi/workspace/replicache"
import { SchemaContext, useSchemaContext } from "./context"
import { List } from "./List"
import { NextSchemaStore } from "@bumi/workspace/data/next/schema"

export function Records() {
  const params = useParams()
  const list = createSubscription(NextSchemaStore.list, [])
  const schema = createMemo(() => {
    return list()?.find((s) => s.name === params.schemaName)
  })

  return (
    <Show when={schema()}>
      <SchemaContext.Provider value={schema()}>
        <Show when={useSchemaContext()}>
          <Routes>
            <Route path="" element={<List />} />
            <Route path="view/:view" element={<List />} />
          </Routes>
        </Show>
      </SchemaContext.Provider>
    </Show>
  )
}
