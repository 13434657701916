import type { Field } from "@bumi/core/field"
import { ReadTransaction, WriteTransaction } from "replicache"
import { RecordStore } from "../record"
import { parseTimestamp } from "../schema"

export function key(fieldID: string) {
  return `/field/${fieldID}`
}

export async function put(tx: WriteTransaction, field: Field.Info) {
  await tx.put(key(field.fieldID), field)
}

export function list() {
  return async (tx: ReadTransaction) => {
    const result = await tx.scan({ prefix: key("") }).toArray()
    return (result || []) as Field.Info[]
  }
}

export function fromID(fieldID: string) {
  return async (tx: ReadTransaction) => {
    const result = await tx.get(key(fieldID))
    return result as Field.Info
  }
}

export function forSchema(schemaID: string) {
  return async (tx: ReadTransaction) => {
    const all = await list()(tx)
    return all.filter(
      (field) =>
        field.owner.type === "schema" && field.owner.schemaID === schemaID
    )
  }
}

export function readable(field: Field.Info, value: any) {
  return async (tx: ReadTransaction) => {
    if (field.shape.type === "DATE") {
      const relative = field.shape.relative
        ? (() => {
            const units = [
              "years",
              "months",
              "weeks",
              "days",
              "hours",
              "minutes",
              "seconds",
            ] as const
            if (!value) return ""
            const datetime = parseTimestamp(value)

            const diff = datetime.diffNow(units as any)
            const unit = (() => {
              for (const unit of units) {
                if (Math.abs(diff[unit]) > 0) {
                  return unit
                }
              }
              return "seconds"
            })()
            return (
              " (" +
              datetime.diffNow(unit).toHuman({
                signDisplay: "never",
                maximumFractionDigits: 0,
              }) +
              ")"
            )
          })()
        : ""
      return value + relative
    }
    if (field.shape.type === "TABLE") {
      const result = []
      for (const row of value || []) {
        const parts = [] as string[]

        for (const fieldID of field.shape.fields) {
          const field = await fromID(fieldID)(tx)
          const value = await readable(field, row[fieldID])(tx)
          if (value) parts.push(value)
        }

        result.push(parts.join(", "))
      }
      return result.join("\n")
    }
    if (field.shape.type === "PHONE") {
      return value?.raw
    }
    if (field.shape.type === "RELATION") {
      const relation = await RecordStore.fromID(field.shape.schemaID, value)(tx)
      return relation?.name
    }
    if (field.shape.type === "MULTI_SELECT") {
      const options = field.shape.options
      return (value || [])
        .map(
          (v: any) => options.find((o) => o.id === v || o.name === v)?.name || v
        )
        .join(", ")
    }
    if (field.shape.type === "SINGLE_SELECT") {
      return (
        field.shape.options.find((o) => o.id === value || o.name === value)
          ?.name || value
      )
    }
    return value
  }
}

export * as NextFieldStore from "./field"
