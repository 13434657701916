import { ReadTransaction } from "replicache"
import type { Business } from "@bumi/core/business"
import type { Workspace } from "@bumi/core/workspace"

export * as CredentialStore from "./credentials"

export function business() {
  return async (tx: ReadTransaction) => {
    const result = await tx.get("/business")
    return result as Business.Info
  }
}

export function workspace() {
  return async (tx: ReadTransaction) => {
    const result = await tx.get("/workspace")
    return result as Workspace.Info
  }
}
