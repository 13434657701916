import { Field } from "@bumi/core/field"
import { Avatar, Row, Tag } from "@bumi/ui"
import { CellMask } from "@bumi/ui/DataGrid2"
import { utility } from "@bumi/ui/theme"
import { DateTime } from "luxon"
import { createMemo, For, JSXElement, Match, Show, Switch } from "solid-js"
import { Filter } from "../data/filter"
import { RecordStore } from "../data/record"
import type { Session } from "@bumi/core/session"
import { UserStore } from "../data/user"
import { createSubscription } from "../replicache"
import { parseTimestamp } from "../data/schema"

export function FieldCell(props: { field: Field.Info; value: any }) {
  return (
    <Switch>
      <Match when={props.value == null}>
        <CellMask title={JSON.stringify(props.value)} />
      </Match>
      <Match
        when={props.field.shape.type === "MULTI_SELECT" && props.field.shape}
      >
        {(shape) => {
          return (
            <Row space="sm">
              <For each={props.value || []}>
                {(item: string) => {
                  const option = createMemo(() =>
                    shape().options.find(
                      (o) => o.name === item || o.id === item
                    )
                  )
                  return (
                    <Tag
                      title={item}
                      style={{
                        border: "1px solid",
                        "border-color": utility.color(option()?.id || "")
                          .foreground,
                        background: utility.color(option()?.id || "")
                          .background,
                      }}
                    >
                      {option()?.name || "Other"}
                    </Tag>
                  )
                }}
              </For>
            </Row>
          )
        }}
      </Match>
      <Match
        when={props.field.shape.type === "SINGLE_SELECT" && props.field.shape}
      >
        {(shape) => {
          const option = createMemo(() =>
            shape().options.find(
              (o) => o.name === props.value || o.id === props.value
            )
          )
          return (
            <Row>
              <Tag
                title={props.value}
                data-option-id={option()?.id}
                data-field-id={props.field.fieldID}
                style={{
                  border: "1px solid",
                  "border-color":
                    option()?.color ||
                    utility.color(option()?.name || "").foreground,
                  background:
                    option()?.color?.replace("82%", "95%") ||
                    utility.color(option()?.name || "").background,
                }}
              >
                {option()?.name || "Other"}
              </Tag>
            </Row>
          )
        }}
      </Match>
      <Match when={props.field.shape.type === "RELATION" && props.field.shape}>
        {(shape) => {
          const record = createSubscription(() =>
            RecordStore.fromID(shape().schemaID, props.value)
          )
          return <CellMask>{record()?.name}</CellMask>
        }}
      </Match>
      <Match when={props.field.shape.type === "USER" && props.field.shape}>
        {(_shape) => {
          const user = createSubscription(() => UserStore.fromID(props.value))
          return <CellMask>{user()?.nameDisplay}</CellMask>
        }}
      </Match>
      <Match when={props.field.shape.type === "DATE" && props.field.shape}>
        {(shape) => {
          const units = [
            "years",
            "months",
            "weeks",
            "days",
            "hours",
            "minutes",
            "seconds",
          ] as const
          const datetime = createMemo(() => parseTimestamp(props.value))
          const relative = createMemo(() => {
            if (!datetime()) return ""
            const diff = datetime().diffNow(units as any)
            const unit = (() => {
              for (const unit of units) {
                if (Math.abs(diff[unit]) > 0) {
                  return unit
                }
              }
              return "seconds"
            })()
            return datetime().diffNow(unit).toHuman({
              signDisplay: "never",
              maximumFractionDigits: 0,
            })
          })
          return (
            <CellMask>
              {datetime().toLocaleString(DateTime.DATE_MED)}
              <Show when={shape().relative}> ({relative()})</Show>
            </CellMask>
          )
        }}
      </Match>
      <Match when={props.field.shape.type === "BOOLEAN"}>
        <CellMask title={props.value}>{props.value ? "Yes" : "No"}</CellMask>
      </Match>
      <Match when={true}>
        <CellMask data-type={props.field.shape.type} title={props.value}>
          {props.value}
        </CellMask>
      </Match>
    </Switch>
  )
}

export function ActorCell(props: { actor: Session.Info["creator"] }) {
  return (
    <Switch>
      <Match when={!props.actor}>
        <span />
      </Match>
      <Match when={props.actor?.type === "system" && props.actor}>System</Match>
      <Match keyed when={props.actor?.type === "user" && props.actor}>
        {(actor) => {
          const user = createSubscription(() =>
            UserStore.fromID(actor.properties.id)
          )
          return (
            <Row space="sm" vertical="center">
              <Avatar
                size="xs"
                hash={user()?.userID}
                title={user()?.nameFirst + " " + user()?.nameLast}
              />
              <span>{user()?.nameDisplay}</span>
            </Row>
          )
        }}
      </Match>
      <Match when={props.actor.type === "api" && props.actor}>API</Match>
    </Switch>
  )
}

export interface Column<Value = any> {
  columnID: string
  fieldID?: string
  header: string
  sorter?: (a: Value, b: Value) => number
  cell: (props: { column: Column<Value>; row: Value; value: any }) => JSXElement
  value?: (row: Value) => any
}

export interface View {
  name?: string
  columns: string[]
  filters: Filter.Info[]
}
