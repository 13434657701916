import { For, createEffect } from "solid-js"
import { RecordStore } from "../data/record"
import { createSubscription, useReplicache } from "../replicache"

export function Debug() {
  const rep = useReplicache()
  const record = createSubscription(() =>
    RecordStore.fromID("bumi.customers", "01H5NYWKC04FD1NC1DJRECSYEJ")
  )

  return (
    <div>
      <div
        onClick={() =>
          rep.mutate.record_data_set({
            recordID: "01H5NYWKC04FD1NC1DJRECSYEJ",
            data: {
              "0001K203ST0000000000000000": { value: Math.random().toString() },
            },
          })
        }
      >
        {record()?.name}
      </div>
      <div>{record()?.data?.["0001ZCPHZ20000000000000000"]}</div>
      <For each={Object.entries(record()?.data || {})}>
        {([fieldID, value]) => (
          <div>
            {fieldID}: {value}
          </div>
        )}
      </For>
    </div>
  )
}
