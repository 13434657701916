import {
  ParentProps,
  createContext,
  createEffect,
  createMemo,
  useContext,
} from "solid-js"
import { useAuth } from "./auth"
import { createSubscription } from "../replicache"
import { UserStore } from "../data/user"
import { CredentialStore } from "../data/next/credentials"

interface State {
  internal: boolean
  newSessions: boolean
}

const context = createContext<State>()

export function FeatureProvider(props: ParentProps) {
  const auth = useAuth()
  const me = createSubscription(() => UserStore.fromID(auth.userID))
  const business = createSubscription(CredentialStore.business)

  const internal = createMemo(() => {
    const email = me()?.email
    return (
      (email?.endsWith("@withbumi.com") || email?.endsWith("@example.com")) ===
      true
    )
  })

  const newSessions = createMemo(() => business()?.namespace === "yinova")

  return (
    <context.Provider
      value={{
        get internal() {
          return internal()
        },
        get newSessions() {
          return newSessions()
        },
      }}
    >
      {props.children}
    </context.Provider>
  )
}

export function useFeature() {
  const feature = useContext(context)
  if (!feature) throw new Error("No feature context")
  return feature
}
