import type { Template } from "@bumi/core/template"
import { ReadTransaction, WriteTransaction } from "replicache"

function key(templateID: string) {
  return `/template/${templateID}`
}

export async function put(tx: WriteTransaction, template: Template.Info) {
  await tx.put(key(template.templateID), template)
}

export function list() {
  return async (tx: ReadTransaction) => {
    const result = await tx.scan({ prefix: key("") }).toArray()
    return (result || []) as Template.Info[]
  }
}

export function fromID(templateID: string) {
  return async (tx: ReadTransaction) => {
    const result = await tx.get(key(templateID))
    return result as Template.Info
  }
}

export * as NextTemplateStore from "./template"
