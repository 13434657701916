import { ReadTransaction, Replicache, WriteTransaction } from "replicache"
import {
  ParentProps,
  batch,
  createContext,
  createEffect,
  createMemo,
  createResource,
  createSignal,
  onCleanup,
  useContext,
} from "solid-js"
import { RecordStore } from "./data/record"
import { Client } from "@bumi/functions/replicache/framework.js"
import type { ServerType } from "@bumi/functions/replicache/next"
import {
  NextSessionStore,
  SessionDetailStore,
  SessionStore,
} from "./data/next/session"
import { NextTemplateStore } from "./data/next/template"
import { createStore, produce, reconcile } from "solid-js/store"
import { NextFieldStore } from "./data/next/field"
import { NextSchemaStore } from "./data/next/schema"
import { mapValues } from "remeda"
import { useAuth } from "./providers/auth"
import { DateTime } from "luxon"
import { makeEventListener } from "@solid-primitives/event-listener"

const ReplicacheContext = createContext<ReturnType<typeof createReplicache>>()

function createReplicache() {
  const auth = useAuth()

  const mutators = new Client<ServerType>()
    .mutation("session_create", async (tx, args) => {
      const template = await NextTemplateStore.fromID(args.templateID)(tx)

      await SessionStore.set(tx, args.sessionID!, {
        templateID: args.templateID,
        sessionID: args.sessionID!,
        queued: false,
        name: template.name,
        tags: template.tags.default || [],
        assignee: auth.userID,
        creator: {
          type: "user",
          properties: {
            workspace: "",
            id: "",
          },
        },
        timeUpdated: DateTime.now().toSQL(),
        timeCreated: DateTime.now().toSQL(),
        recordID: args.recordID,
      })

      await SessionDetailStore.set(tx, args.sessionID, {
        sessionID: args.sessionID,
        data: {},
        pages: template.pages,
        hidden: template.hidden,
      })
    })
    .mutation("session_start", async (tx, args) => {
      await tx.put(`/session-syncing/${args}`, true)
    })
    .mutation("session_remove", async (tx, args) => {
      await tx.del(NextSessionStore.key(args))
    })
    .mutation("session_assign", async (tx, args) => {
      const session = { ...(await NextSessionStore.fromID(args.sessionID)(tx)) }
      if (!args.userID) delete session.assignee
      else session.assignee = args.userID
      await NextSessionStore.put(tx, session)
    })
    .mutation("session_toggle_hidden", async (tx, args) => {
      await SessionDetailStore.update(tx, args.sessionID, (detail) => {
        const { hidden, pages } = detail
        function mark(key: string) {
          if (args.hidden) {
            hidden[key] = true
            return
          }
          delete hidden[key]
        }
        if (args.sectionID) {
          mark(args.sectionID)
        }

        if (!args.sectionID) {
          const page = pages.find((x) => x.pageID === args.pageID)
          mark(args.pageID)
          for (const section of page?.sections || []) {
            mark(section.sectionID)
          }
        }
      })
    })
    .mutation("session_scheduled_set", async () => {})
    .mutation("session_data_set", async (tx, args) => {
      await SessionDetailStore.update(tx, args.sessionID, (session) => {
        for (const [fieldID, entry] of Object.entries(args.data)) {
          if (!session.data[fieldID]) session.data[fieldID] = {}
          if (entry.value !== undefined) {
            session.data[fieldID].value = entry.value
          }
          if (entry.notes !== undefined) {
            session.data[fieldID].notes = entry.notes
          }
        }
      })
    })
    .mutation("session_notes_set", async (tx, args) => {
      await SessionDetailStore.update(tx, args.sessionID, (session) => {
        session.notes = args.notes
      })
    })
    .mutation("session_subject_set", async (tx, args) => {
      await SessionStore.update(tx, args.sessionID, (session) => {
        session.subject = args.subject
      })
    })
    .mutation("session_markdown", async () => {})
    .mutation("record_data_set", async (tx, args) => {
      const record = await RecordStore.fromID(
        "bumi.customers",
        args.recordID
      )(tx)
      if (!record) return
      const data: Record<string, any> = {
        ...record.data,
        ...Object.fromEntries(
          Object.entries(args.data)
            .filter(([, value]) => value.value !== undefined)
            .map(([fieldID, value]) => [fieldID, value.value])
        ),
      }
      const notes: Record<string, string> = {
        ...record.notes,
        ...Object.fromEntries(
          Object.entries(args.data)
            .filter(([, value]) => value.notes !== undefined)
            .map(([fieldID, value]) => [fieldID, value.notes!])
        ),
      }
      const schema = await NextSchemaStore.fromID(record.schemaID)(tx)
      const fields = await NextFieldStore.list()(tx)
      for (const field of fields) {
        if (
          field.owner.type === "schema" &&
          field.owner.schemaID === record.schemaID
        ) {
          if (field.shape.type === "FORMULA") {
            data[field.fieldID] = eval(field.shape.formula)(
              mapValues(data, (value) => ({ value }))
            )
          }
        }
      }
      await RecordStore.put(tx, {
        ...record,
        name: data[schema.identityName!] || record.name || "Unnamed",
        data,
        notes,
      })
    })
    .mutation("record_create", async (tx, args) => {
      await RecordStore.put(tx, {
        recordID: args.recordID!,
        schemaID: args.schemaID,
        tags: [],
        name: "Unnamed",
        timeCreated: new Date().toISOString(),
        notes: {},
        data: {},
      })
    })
    .mutation("record_notes_set", async (tx, args) => {
      const record = await RecordStore.fromID(
        "bumi.customers",
        args.recordID
      )(tx)
      await RecordStore.put(tx, {
        ...record,
        generalNotes: args.notes,
      })
    })
    .mutation("record_archive", async (tx, recordID) => {
      const record = await RecordStore.fromID("bumi.customers", recordID)(tx)
      if (!record) return
      await RecordStore.remove(tx, record)
    })
    .mutation("session_close", async (tx, args) => {
      const session = await SessionStore.get(tx, args.sessionID)
      if (!session) return
      const record = await RecordStore.fromID(
        "bumi.customers",
        session.recordID!
      )(tx)

      await SessionStore.update(tx, args.sessionID, (session) => {
        session.outcome = args.outcome
        session.timeClosed = DateTime.now().toSQL()
      })

      await SessionDetailStore.update(tx, args.sessionID, (detail) => {
        detail.notes = record?.generalNotes || ""
        detail.snapshots = {
          [record?.recordID!]: (() => {
            const result = {} as Record<string, { value?: any; notes?: any }>
            for (const [key, value] of Object.entries(record?.data || {})) {
              result[key] = { value }
            }
            for (const [key, notes] of Object.entries(record?.notes || {})) {
              result[key] = {
                ...result[key],
                notes,
              }
            }
            return result
          })(),
        }
      })
    })
    .mutation("session_open", async (tx, args) => {
      await SessionStore.update(tx, args, (session) => {
        delete session.timeClosed
        delete session.closer
      })
    })
    .mutation("replicache_window_create", async () => {})
    .build()

  const replicache = new Replicache({
    name: auth.userID,
    auth: `Bearer ${auth.token}`,
    licenseKey: "l0713c65e5d6c4a1192507792ab7d6fba",
    pullURL: import.meta.env.VITE_API_URL + "/replicache/pull",
    pushURL: import.meta.env.VITE_API_URL + "/replicache/push",
    pullInterval: 1000 * 30,
    mutators,
  })
  makeEventListener(window, "focus", () => {
    replicache.pull()
  })

  replicache.getAuth = async () => {
    location.href = auth.login
    await new Promise((r) => setTimeout(r, 5000))
    return undefined
  }

  const pusher = replicache.pusher
  replicache.pusher = async (request, val) => {
    const result = await pusher(request, val)
    replicache.pull()
    return result
  }

  replicache.puller = async (request) => {
    if (!document.hasFocus())
      return {
        httpRequestInfo: {
          httpStatusCode: 200,
          errorMessage: "",
        },
        response: {
          cookie: request.cookie,
          lastMutationIDChanges: {},
          patch: [],
        },
      }
    const result = await fetch(
      import.meta.env.VITE_API_URL + "/replicache/pull",
      {
        body: JSON.stringify(request),
        method: "POST",
        headers: {
          authorization: `Bearer ${auth.token}`,
        },
      }
    )
    let body = await result.json()
    if (body.redirect) {
      body = await fetch(body.redirect).then((r) => r.json())
    }
    return {
      httpRequestInfo: {
        httpStatusCode: 200,
        errorMessage: "",
      },
      response: body,
    }
  }

  return replicache
}

export function ReplicacheProvider(props: ParentProps) {
  const rep = createReplicache()
  onCleanup(() => {
    if (rep) rep.close()
  })
  return (
    <ReplicacheContext.Provider value={rep}>
      {props.children}
    </ReplicacheContext.Provider>
  )
}

export function useReplicache() {
  const replicache = useContext(ReplicacheContext)
  if (!replicache) {
    throw new Error("Replicache context not found")
  }

  return replicache
}

export function createSubscription<R, D = undefined>(
  body: () => (tx: ReadTransaction) => Promise<R>,
  initial?: D
) {
  const [store, setStore] = createStore({ result: initial as any })
  const replicache = useReplicache()

  let unsubscribe: () => void

  createEffect(() => {
    if (unsubscribe) unsubscribe()
    setStore({ result: initial as any })

    // @ts-expect-error
    unsubscribe = replicache.subscribe(body(), {
      onData: (val) => {
        setStore("result", reconcile(structuredClone(val)))
      },
    })
  })

  onCleanup(() => {
    if (unsubscribe) unsubscribe()
  })

  return () => store.result as R | D
}

export function createGet<T extends any>(p: () => string) {
  let unsubscribe: () => void
  const rep = useReplicache()

  const [data, setData] = createStore({
    value: undefined as T | undefined,
  })
  const [ready, setReady] = createSignal(false)

  createResource(
    () => p(),
    (path) => {
      return new Promise((resolve) => {
        if (unsubscribe) unsubscribe()
        batch(() => {
          setData("value", undefined)
          setReady(false)
        })

        unsubscribe = rep.experimentalWatch(
          (diffs) => {
            batch(() => {
              for (const diff of diffs) {
                if (diff.op === "add") {
                  setData("value", structuredClone(diff.newValue) as T)
                }
                if (diff.op === "change") {
                  setData(
                    "value",
                    reconcile(structuredClone(diff.newValue) as T)
                  )
                }
                if (diff.op === "del") setData("value", undefined)
              }
              setReady(true)
              resolve(true)
            })
          },
          {
            prefix: path,
            initialValuesInFirstDiff: true,
          }
        )
      })
    }
  )

  onCleanup(() => {
    if (unsubscribe) unsubscribe()
  })

  const result = () => {
    ready()
    return data.value
  }
  Object.defineProperty(result, "ready", { get: ready })

  return result as {
    (): T | undefined
    ready: boolean
  }
}

export function createScan<T extends any>(
  p: () => string,
  refine?: (values: T[]) => T[]
) {
  let unsubscribe: () => void

  const [data, setData] = createStore<T[]>([])
  const [ready, setReady] = createSignal(false)
  const keyToIndex = new Map<string, number>()
  const indexToKey = new Map<number, string>()

  const rep = useReplicache()

  const [resource] = createResource(
    () => p(),
    (path) =>
      new Promise<boolean>((resolve) => {
        if (unsubscribe) unsubscribe()
        batch(() => {
          setReady(false)
          setData([])
        })

        unsubscribe = rep.experimentalWatch(
          (diffs) => {
            batch(() => {
              // Faster set if we haven't seen any diffs yet.
              if (!ready()) {
                const values: T[] = []
                for (const diff of diffs) {
                  if (diff.op === "add") {
                    const value = structuredClone(diff.newValue) as T
                    const index = values.push(value)
                    keyToIndex.set(diff.key, index - 1)
                    indexToKey.set(index - 1, diff.key)
                  }
                }
                setData(values)
                setReady(true)
                resolve(true)
                return
              }
              setData(
                produce((state) => {
                  for (const diff of diffs) {
                    if (diff.op === "add") {
                      const index = state.push(
                        structuredClone(diff.newValue) as T
                      )
                      keyToIndex.set(diff.key, index - 1)
                      indexToKey.set(index - 1, diff.key)
                    }
                    if (diff.op === "change") {
                      state[keyToIndex.get(diff.key)!] = reconcile(
                        structuredClone(diff.newValue) as T
                      )(structuredClone(diff.oldValue))
                    }
                    if (diff.op === "del") {
                      const toRemove = keyToIndex.get(diff.key)!
                      const last = state[state.length - 1]
                      const lastKey = indexToKey.get(state.length - 1)!

                      state[toRemove] = last
                      keyToIndex.delete(diff.key)
                      indexToKey.delete(toRemove)

                      keyToIndex.set(lastKey, toRemove)
                      indexToKey.set(toRemove, lastKey)
                      indexToKey.delete(state.length - 1)

                      state.pop()
                    }
                  }
                })
              )

              setReady(true)
            })
          },
          {
            prefix: path,
            initialValuesInFirstDiff: true,
          }
        )
      })
  )

  onCleanup(() => {
    if (unsubscribe) unsubscribe()
  })

  const result = createMemo(() => {
    resource()
    return refine ? refine(data) : data
  })
  Object.defineProperty(result, "ready", { get: ready })

  return result as {
    (): T[]
    ready: boolean
  }
}

export function define<
  T extends Record<string, any>,
  Get extends (arg: any) => string[] = (arg: any) => string[]
>(input: { get: Get; scan: () => string[] }) {
  const result = {
    watch: {
      get: (cb: () => Parameters<Get>[0]) => {
        return createGet<T>(() => result.path.get(cb()))
      },
      scan: (filter?: (value: T) => boolean) => {
        return createScan<T>(
          () => result.path.scan(),
          filter ? (values) => values.filter(filter) : undefined
        )
      },
      find: (find: (value: T) => boolean) => {
        const filtered = createScan<T>(
          () => result.path.scan(),
          (values) => [values.find(find)!]
        )

        return createMemo(() => filtered().at(0))
      },
    },
    path: {
      get: (args: Parameters<Get>[0]) => {
        const path = input.get(args)
        return `/` + path.join("/")
      },
      scan: () => {
        const path = input.scan()
        return `/` + path.join("/")
      },
    },
    async get(tx: ReadTransaction, args: Parameters<Get>[0]) {
      const item = await tx.get(result.path.get(args))
      return item as T | undefined
    },
    async set(
      tx: WriteTransaction,
      args: Parameters<Get>[0],
      item: Partial<T>
    ) {
      await tx.put(result.path.get(args), item as any)
    },
    async update(
      tx: WriteTransaction,
      args: Parameters<Get>[0],
      updator: (input: T) => void
    ) {
      const value = structuredClone(await result.get(tx, args))
      if (!value) throw new Error("Not found")
      await Promise.resolve(updator(value))
      await result.set(tx, args, value)
    },
  }
  return result
}
