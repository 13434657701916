import { Schema } from "@bumi/core/schema"
import { ReadTransaction, WriteTransaction } from "replicache"

function key(schemaID: string) {
  return `/schema/${schemaID}`
}

export async function put(tx: WriteTransaction, schema: Schema.Info) {
  await tx.put(key(schema.schemaID), schema)
}

export function list() {
  return async (tx: ReadTransaction) => {
    const result = await tx.scan({ prefix: key("") }).toArray()
    return (result || []) as Schema.Info[]
  }
}

export function fromID(schemaID: string) {
  return async (tx: ReadTransaction) => {
    const result = await tx.get(key(schemaID))
    return result as Schema.Info
  }
}

export * as NextSchemaStore from "./schema"
