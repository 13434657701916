import { z } from "zod"
import { DateTime } from "luxon"
import type { Field } from "@bumi/core/field"

const FilterTypes = {
  boolean_true: {
    label: "boolean",
    shape: z.never(),
  },
  boolean_false: {
    label: "boolean",
    shape: z.never(),
  },
  string_is: {
    label: "is",
    shape: z.any(),
    default: "",
  },
  string_is_not: {
    label: "is not",
    shape: z.string(),
    default: "",
  },
  string_contains: {
    label: "contains",
    shape: z.string(),
    default: "",
  },
  string_any: {
    label: "any of",
    shape: z.string().array(),
    default: [],
  },
  string_not_contains: {
    label: "does not contain",
    shape: z.string(),
    default: "",
  },
  any_of: {
    label: "any of",
    shape: z.any().array(),
  },
  is_empty: {
    label: "is empty",
    shape: z.never(),
    default: "",
  },
  is_not_empty: {
    label: "is not empty",
    shape: z.never(),
    default: "",
  },
  date_between: {
    label: "is between",
    shape: z.object({
      start: z.string(),
      end: z.string(),
    }),
    default: {
      start: DateTime.now().startOf("month").toISODate(),
      end: DateTime.now().endOf("month").toISODate(),
    },
  },
} as const

export type Info = {
  [key in keyof typeof FilterTypes]: {
    op: key
    value?: z.infer<(typeof FilterTypes)[key]["shape"]>
  }
}[keyof typeof FilterTypes] & {
  columnID: string
}

const StringFilters: (keyof typeof FilterTypes)[] = [
  "string_is",
  "string_is_not",
  "string_contains",
  "string_not_contains",
  "is_empty",
  "is_not_empty",
]

export const Mapping = {
  TEXT: StringFilters,
  LONG_TEXT: StringFilters,
  DATE: ["date_between"],
  FORMULA: StringFilters,
  DEFAULT: ["is_empty", "is_not_empty"],
} as unknown as Record<
  Field.Info["shape"]["type"] | "DEFAULT",
  (keyof typeof FilterTypes)[]
>

export function apply(filter: Info, value: any) {
  switch (filter.op) {
    case "boolean_true":
      if (value !== true) return false
      break
    case "boolean_false":
      if (value !== false) return false
      break
    case "string_is":
      if (value !== filter.value) return false
      break

    case "string_any":
      if (
        filter.value &&
        filter.value.length > 0 &&
        !filter.value.includes(value)
      )
        return false
      break

    case "string_is_not":
      if (value === filter.value) return false
      break

    case "string_contains":
      if (!value?.toLowerCase()?.includes(filter.value?.toLowerCase()))
        return false
      break

    case "string_not_contains":
      if (value?.toLowerCase()?.includes(filter.value?.toLowerCase()))
        return false
      break

    case "is_empty":
      if (value) return false
      if (Array.isArray(value) && value.length > 0) return false
      break

    case "is_not_empty":
      if (!value) return false
      if (Array.isArray(value) && value.length === 0) return false
      break

    case "date_between":
      if (!value) return false
      if (filter.value?.start && value < filter.value.start) return false
      if (filter.value?.end && value >= filter.value.end) return false
      break

    case "any_of":
      if (!value) return false
      if (!filter.value) return true
      return filter.value.includes(value)
  }
  return true
}

export * as Filter from "./filter"
