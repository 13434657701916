import { NextFieldStore } from "@bumi/workspace/data/next/field"
import { createSubscription } from "@bumi/workspace/replicache"
import { DateTime } from "luxon"
import { Match, Show, Switch, createMemo } from "solid-js"

interface Props {
  fieldID: string
  value: any
}

export function FieldValue(props: Props) {
  const field = createSubscription(() => NextFieldStore.fromID(props.fieldID))
  const state = {
    get field() {
      return field()!
    },
  }

  return (
    <Show when={field()}>
      <Switch>
        <Match when={props.value == null}>{"No Value"}</Match>
        <Match when={state.field.shape.type === "FORMULA" && state.field.shape}>
          {(shape) => <>{props.value}</>}
        </Match>
        <Match when={state.field.shape.type === "TEXT" && state.field.shape}>
          {(shape) => <>{props.value}</>}
        </Match>
        <Match
          when={state.field.shape.type === "LONG_TEXT" && state.field.shape}
        >
          {(shape) => <>{props.value}</>}
        </Match>
        <Match when={state.field.shape.type === "PHONE" && state.field.shape}>
          {(shape) => <>{props.value.raw || "No number"}</>}
        </Match>
        <Match when={state.field.shape.type === "DATE" && state.field.shape}>
          {(shape) => {
            const datetime = createMemo(() => DateTime.fromSQL(props.value))
            const relative = createMemo(() => {
              if (!shape().relative) return ""
              const units = [
                "years",
                "months",
                "weeks",
                "days",
                "hours",
                "minutes",
                "seconds",
              ] as const
              const diff = datetime().diffNow(units as any)
              const unit = (() => {
                for (const unit of units) {
                  if (Math.abs(diff[unit]) > 0) {
                    return unit
                  }
                }
                return "seconds"
              })()
              return (
                " (" +
                datetime().diffNow(unit).toHuman({
                  signDisplay: "never",
                  maximumFractionDigits: 0,
                }) +
                ")"
              )
            })
            return (
              <>
                {datetime().toLocaleString(DateTime.DATE_FULL)}
                {relative()}
              </>
            )
          }}
        </Match>
        <Match
          when={state.field.shape.type === "SINGLE_SELECT" && state.field.shape}
        >
          {(shape) => {
            const value = createMemo(
              () =>
                shape().options.find((o) => o.id === props.value)?.name ||
                props.value
            )
            return <>{value()}</>
          }}
        </Match>
        <Match
          when={state.field.shape.type === "MULTI_SELECT" && state.field.shape}
        >
          {(shape) => {
            const value = createMemo(() =>
              props.value.map(
                (v: string) =>
                  shape().options.find((o) => o.id === v)?.name || props.value
              )
            )
            return <>{value().join(", ")}</>
          }}
        </Match>
      </Switch>
    </Show>
  )
}
