import { Session } from "@bumi/core/session"
import { createScan } from "../replicache"
import { NextSessionStore } from "../data/next/session"
import { RecordStore } from "../data/record"
import { ParentProps, createContext, useContext } from "solid-js"
import { Record } from "@bumi/core/record"

function init() {
  const sessions = createScan<Session.Info>(() => NextSessionStore.key(""))
  const customers = createScan<Record.Info>(() =>
    RecordStore.key("bumi.customers")
  )

  return {
    sessions,
    customers,
  }
}

export const GlobalContext = createContext<ReturnType<typeof init>>()

export function GlobalProvider(props: ParentProps) {
  const state = init()
  return (
    <GlobalContext.Provider value={state}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export function useGlobal() {
  const result = useContext(GlobalContext)
  if (!result) throw new Error("GlobalContext missing")
  return result
}

export function useSessions() {
  return useGlobal().sessions
}

export function useCustomers() {
  return useGlobal().customers
}
