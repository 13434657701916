import { Info, Record } from "@bumi/core/record"
import { RecordStore } from "@bumi/workspace/data/record"
import { createGet } from "@bumi/workspace/replicache"
import { useParams } from "@solidjs/router"
import {
  Accessor,
  ParentProps,
  Show,
  createContext,
  useContext,
} from "solid-js"

const context = createContext<Accessor<Info>>()

export function RecordContext(props: ParentProps) {
  const params = useParams()
  const record = createGet<Record.Info>(() =>
    RecordStore.key("bumi.customers", params.recordID)
  )
  console.log("params", params.recordID)
  return (
    <Show when={record()}>
      {(val) => (
        <context.Provider value={val}>{props.children}</context.Provider>
      )}
    </Show>
  )
}

export function useRecord() {
  const ctx = useContext(context)
  if (!ctx) throw new Error("RecordContext not found")
  return ctx
}
