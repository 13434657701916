import { parseTimestamp } from "./schema"
import { useReplicache } from "../replicache"
import { Window } from "@bumi/core/replicache"

export * as WindowStore from "./window"

export function key(type: string, id: string) {
  return `/replicacheWindow/${type}/${id}`
}

export function list(type: string) {
  return key(type, "")
}

export async function create(
  rep: ReturnType<typeof useReplicache>,
  type: string,
  target: string
) {
  const exists = await rep.query(
    (tx) => tx.get(key(type, target)) as Promise<Window[]>
  )
  if (
    exists &&
    parseTimestamp((exists as any).timeUpdated)
      .diffNow()
      .as("minutes") > 0
  ) {
    return
  }
  const profileID = await rep.profileID
  rep.mutate.replicache_window_create({
    type: type,
    profileID: profileID,
    target,
  })
}
