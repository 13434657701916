import type { Session } from "@bumi/core/session"
import { define } from "@bumi/workspace/replicache"
import { ReadTransaction, WriteTransaction } from "replicache"

export function key(sessionID: string) {
  return `/session/${sessionID}`
}

export async function put(
  tx: WriteTransaction,
  session: Partial<Session.Info>
) {
  await tx.put(key(session.sessionID!), session)
}

export function list() {
  return async (tx: ReadTransaction) => {
    const result = await tx.scan({ prefix: key("") }).toArray()
    return (result || []) as Session.Info[]
  }
}

export function fromID(sessionID: string) {
  return async (tx: ReadTransaction) => {
    const result = await tx.get(key(sessionID))
    return result as Session.Info
  }
}

export function forRecord(recordID: string) {
  return async (tx: ReadTransaction) => {
    const result = await tx.scan({ prefix: key("") }).toArray()
    return ((result || []) as Session.Info[]).filter(
      (s) => s.recordID === recordID
    )
  }
}

export * as NextSessionStore from "./session"

export const SessionDetailStore = define<Session.Detail>({
  scan() {
    throw new Error("Not implemented")
  },
  get(sessionID: string) {
    return ["sessionDetail", sessionID]
  },
})

export const SessionStore = define<Session.Info>({
  scan() {
    return ["session"]
  },
  get(sessionID: string) {
    return ["session", sessionID]
  },
})
