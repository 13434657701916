import { iot, mqtt } from "aws-iot-device-sdk-v2"
import { ParentProps, createEffect, onCleanup, onMount } from "solid-js"
import { useAuth } from "./auth"
import { useReplicache } from "../replicache"
import { useSearchParams } from "@solidjs/router"
import { ulid } from "ulid"

export function RealtimeProvider(props: ParentProps) {
  const auth = useAuth()
  const rep = useReplicache()

  let connection: mqtt.MqttClientConnection
  onMount(() => {
    async function createConnection() {
      console.log("creating new connection")
      const url = import.meta.env.VITE_IOT_HOST
      if (connection) await connection.disconnect()
      const config = iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets()
        .with_clean_session(true)
        .with_client_id("client_" + ulid())
        .with_endpoint(url)
        .with_custom_authorizer(
          "",
          `${import.meta.env.VITE_STAGE}-bumi-authorizer`,
          "",
          auth.token
        )
        .with_keep_alive_seconds(1200)
        .build()
      const client = new mqtt.MqttClient()
      connection = client.new_connection(config)

      connection.on("connect", async () => {
        console.log("WS connected")
        await connection.subscribe(
          `bumi/${import.meta.env.VITE_STAGE}/${auth.workspaceID}/#`,
          mqtt.QoS.AtLeastOnce
        )
      })
      connection.on("interrupt", (e) => {
        console.log("interrupted, restarting", e, JSON.stringify(e))
        // createConnection()
      })
      connection.on("error", (e) => {
        console.log(
          "connection error",
          e,
          e.error,
          e.name,
          e.cause,
          e.message,
          e.error_code,
          e.error_name
        )
      })
      connection.on("resume", console.log)
      connection.on("message", async (fullTopic, payload) => {
        const splits = fullTopic.split("/")
        // const workspaceID = splits[2]
        const topic = splits[3]
        // const message = new TextDecoder("utf8").decode(new Uint8Array(payload))
        // const parsed = JSON.parse(message)
        if (topic === "poke") {
          rep.pull()
        }
      })
      connection.on("disconnect", console.log)
      await connection.connect()
    }

    createConnection()
  })

  onCleanup(() => {
    if (connection) connection.disconnect()
  })

  return props.children
}
